import React, { useContext } from 'react'
import Navlinks from './Navlinks'
// import Logo from '../../images/logo.png'
import Logo from "../../../components/theme/Header/Logo";
import styled from "styled-components";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { Helmet } from "react-helmet";

const HeaderWrapper = styled.header`
	text-align: center;
	color: rgba(0,0,0,0.45);
	margin: 0;
	padding: 1.5em 0 1em 0;
	border-bottom: ${({ theme }) => (theme === 'light' ? 'none' : '1px solid rgba(255,255,255,0.5)')};

	div {
		margin: 0;
		a {
			vertical-align: middle;
		}
	}
`

// prevent the logo from jumping when navbar item is clicked
const LogoWrapper = styled.div`
	height: 40px;
	min-height: 40px;
	max-height: 40px;
`;

const Header = ({ lang, links, themeColors, isMobileDevice }) => {
	const { theme } = useContext(ThemeContext);
	const navLinks = isMobileDevice
		? ''
		: <Navlinks
			lang={lang}
			links={links}
			themeColors={themeColors}
		/>

	return (
		<HeaderWrapper theme={theme}>
			<Helmet>
				<style>
					{theme === 'light'
						? 'body { background-color: rgb(245, 247, 249); }'
						: 'body { background-color: #000; }'
					}
				</style>
			</Helmet>
			<LogoWrapper>
				<Logo lang={lang} height={"35px"} />
			</LogoWrapper>
			{navLinks}
		</HeaderWrapper>
	)
}

export default Header