import React, { useState } from 'react'
import data from "../../data/Data";
import Header from './partials/Header';
import Footer from './partials/Footer'
import "../styles.scss"
import Sidebar from "../../components/theme/Header/Sidebar";
import Hamburger from "../../components/theme/Header/Hamburger";
import { Overlay } from "../../components/theme/Header/styles";
import styled from "styled-components";

const AppContainer = styled.div`
	z-index: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
`;

const SidebarWrapper = styled.div`
  display: block;
  @media only screen and (min-width: 971px) {
    display: none;
  }
`;

export default function Layout({ lang, children }) {
	// const maxMobileWidth = 970;
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const getPageThemeColors = (data) => {
		const themeColors = {}
		Object.keys(data).forEach(key => {
			const color = data[key].themeColor
			if (color) {
				themeColors[key] = color
			}
		})
		return themeColors
	}

	const updateSidebarStatus = () => setIsSidebarOpen(!isSidebarOpen);
	const pageThemeColors = getPageThemeColors(data)

	return (
		<React.Fragment>
			<SidebarWrapper>
				<Hamburger sidebar={isSidebarOpen} toggle={updateSidebarStatus} />
				<Sidebar lang={lang} sidebar={isSidebarOpen} toggle={updateSidebarStatus} notHomePage={true} />
			</SidebarWrapper>
			<AppContainer>
				<Overlay sidebar={isSidebarOpen} onClick={updateSidebarStatus} />
				<Header
					lang={lang}
					themeColors={pageThemeColors}
				/>
				{children}
				<Footer lang={lang} />
			</AppContainer>
		</React.Fragment>
	)
}

