import React, {useContext} from 'react'
import styled from "styled-components";
import {ThemeContext} from "../../../providers/ThemeProvider";

const FooterContainer = styled.footer`
    font-family: 'SF Pro Text Light', sans-serif;
    font-size: 11px;
    color: ${({ theme }) => (theme === 'light' ? 'rgba(0,0,0,0.45)' : 'rgba(255,255,255,0.8)')};
    text-align: center;
    padding: 20px 0 11px 0;
    margin-bottom: 1em;
    border-top: ${({ theme }) => (theme === 'light' ? 'none' : '1px solid rgba(255,255,255,0.5)')};
    
    .icon-gap {
        display: inline-block;
        width: 10px;
    }
    
    .icon {
      height: 18px;
    }
    
    &:hover {
        a {
          color: rgb(236, 133, 19);
        }
    }
    
    a {
        color: ${({ theme }) => (theme === 'light' ? 'rgba(0,0,0,0.45)' : 'rgba(255,255,255,0.8)')};
        cursor: pointer;
        text-decoration: none;
    }
`

const Footer = ({ lang }) => {
    const isEn = lang === "en";
    const establishYear = 2018
    const currYear = (new Date()).getFullYear()
    const year = establishYear < currYear ? `${establishYear} - ${currYear}` : currYear.toString()
    const { theme } = useContext(ThemeContext);

    return (
        <FooterContainer theme={theme} className='footer'>
            <div>
                <a href='mailto:tjiang@rutgers.edu'>
                    {isEn ? "Email" : "电子邮箱"}
                </a>
                <span className='icon-gap'/>
                <a href='https://rccs.rutgers.edu/people/director' target='_blank' rel='noopener noreferrer'>
                    {isEn ? "Rutgers" : "罗格斯大学"}
                </a>
                <span className='icon-gap'/>
                <a href='https://www.linkedin.com/in/tao-jiang-bb80b837/' target='_blank' rel='noopener noreferrer'>
                    {isEn ? "Linkedin" : "领英"}
                </a>
                <span className='icon-gap'/>
                <a href='https://twitter.com/taojiangscholar' target='_blank' rel='noopener noreferrer'>
                    {isEn ? "Twitter" : "推特"}
                </a>
                <span className='icon-gap'/>
                <a href='https://zirk.us/@TaoJiang' target='_blank' rel='noopener noreferrer'>
                    {isEn ? "Mastodon" : "万象"}
                </a>
            </div>
            <p className='footer-text'>© {year} {isEn ? "Tao Jiang" : "蒋韬保留所有权利"}</p>
            <p className='footer-text'>{ isEn ? "Created by Biyun Wu" : "此网站由 Biyun Wu 开发"}</p>
        </FooterContainer>
    )
}

export default Footer
