import React from "react";
import Papers from "../subpage_src/components/Papers";
import data from '../data/Data';
import Layout from "../subpage_src/components/Layout";

const FreudAndChina = () => {
    return (
        <Layout lang={"en"}>
            <Papers
                lang={"en"}
                paperItems = {data.papers}
            />
        </Layout>
    )

}

export default FreudAndChina;