import React, { useContext } from 'react'
import { Link } from "gatsby";
import data from "../../../data/Data";
import ToggleLang from "../../../components/theme/Header/ToggleLang";
import styled from "styled-components";
import { ThemeContext } from "../../../providers/ThemeProvider";
import ToggleTheme from "../../../components/theme/Header/ToggleTheme";
//
const NavWrapper = styled.ul`
  display: block;
  @media only screen and (max-width: 970px) {
    display: none;
  }
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  text-align: center;
  margin: 0 0 10px 0;
  padding: 0 5px;
  font-family: 'SF Pro Text Light', sans-serif;
  font-size: 14px;
  font-weight: 600;
  transition: height 0.3s;
  
  .link-separator {
    padding: 0 7px;
    color: rgba(0,0,0,0.1);
    &::selection {
      background: none;
    }
  }

  .nav-item {
    list-style: none;
    display: inline-block;
    line-height: 1.8em;
    
    .nav-link {
      color: ${({ theme }) => (theme === 'light' ? 'rgba(0,0,0,0.45)' : 'rgba(255,255,255,0.8)')};
      text-decoration: none;
      //text-transform: uppercase;
      letter-spacing: 0;
      white-space: nowrap;
    
      &:hover {
        color: ${({ theme }) => (theme === 'light' ? 'rgba(0,0,0,0.84)' : 'rgb(255,255,255)')};
      }
    }
    
    .active {
      // color: $active-link-color;
      color: rgb(236, 133, 19);
      &:hover {
        color: rgb(236, 133, 19);
      }
    }
  }
`

const NavLinks = ({ lang, themeColors, isInSidebar }) => {
  const { theme } = useContext(ThemeContext);

  const getLink = (lang, link) => {
    const langPath = lang === "en" ? "" : "/cn"
    return link.toLowerCase() !== 'home'
      ? (`${langPath}/${link.toLowerCase().replace(/ /g, "_")}/`)
      : (`${langPath}/`)
  }

  const pageNames = data.pageNames
  const pageNamesCN = data.pageNamesCN.map(name => name.toLowerCase())
  // const navLinks = generateNavLinks(lang, pageNames, pageNamesCN, themeColors, isInSidebar)
  return (
    // <ul className="nav">
    //     {navLinks}
    // </ul>
    <NavWrapper theme={theme}>
      {/* {navLinks} */}
      <li className={'nav-item'}>
        <ToggleLang lang={lang} />
      </li>
      {!isInSidebar && <span className='link-separator'>|</span>}
      {pageNames.map((link, idx) =>
        <li className={'nav-item'} key={idx}>
          <Link
            to={getLink(lang, link)}
            className={'nav-link'}
            activeClassName='active'
            partiallyActive={!(link.toLowerCase() === "home")}
          >
            {lang === "en" ? link : pageNamesCN[idx]}
          </Link>
          {!isInSidebar && idx !== pageNames.length && <span className='link-separator'>|</span>}
        </li>
      )}
      <li className={'nav-item'}>
        <ToggleTheme />
      </li>
    </NavWrapper>
  )
}

export default NavLinks;