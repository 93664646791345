import React, { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Seo } from '../../components/common';
import styled from 'styled-components';
import { ThemeContext } from '../../providers/ThemeProvider';

const tagColors = ['#DC3023', 'rgb(240, 157, 56)', 'rgb(0, 216, 208)'];
// const hostPath = 'https://raw.github.com/biyunwu/academic-web/master/src/data/papers/'
const hostPath = '/papers/'; // path name in the `static` folder

const MainContentContainer = styled.div`
  background: ${({ theme }) => (theme === 'light' ? '#fff' : 'none')};
  h2 {
    border-bottom: ${({ theme }) =>
      theme === 'light' ? '1px solid rgba(0,0,0,0.1)' : '1px solid rgba(255,255,255,0.5)'};
  }
`;

const PapersContainer = styled.main`
  max-width: 1200px;
  padding: 0 3%;
  margin: 0 auto;
  color: ${({ theme }) => (theme === 'light' ? 'rgba(0,0,0,.84)' : 'rgb(230, 230, 230)')};
  font-size: 18px;
  background: ${({ theme }) => (theme === 'light' ? '#fff' : 'none')};

  @media only screen and (min-width: 501px){
    padding: 0 6%;
  }
  
  @media only screen and (min-width: 780px) {
    & {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 2em;
    }

    div.options {
      grid-column-start: 5;
      grid-column-end: 6;
    }

    div.papers {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
    }

    div.sticky {
      position: sticky;
      top: 0;
    }
  }

  @media only screen and (max-width: 779px) {
    div.options {
      position: sticky;
      top: 0;
      margin-top: 1em;
      text-align: center !important;
      //background: white;

      h2 {
        visibility: hidden;
        height: 0;
        margin: 0;
      }
    }

    div.label {
      width: auto !important;
      padding: 0.5em 0.5em !important;
    }

    p {
      text-align: left;
      word-break: normal;
    }
  }

  div.options {
    text-align: center;
    width: 100%;
  }

  div.tag-label-container {
    display: inline-block;
    text-align: center;

    &:not(:last-child) {
      margin-right: 2px;
    }

    div.label {
      width: 180px;
      border: none;
      font-family: 'SF Pro Text Regular', sans-serif;
      font-size: 14px;
      color: white;
      padding: 0.5em 0;
      margin: 0.5em 0;
    }
  }

  ul {
    padding: 0 20px;
    line-height: 1.58;
    letter-spacing: -0.003em;

    li {
      p {
        padding: 0 1em 0 0.3em;
        //text-indent: -0.4em;
        text-indent: 0;
        &:hover {
          transition: border-left 0.2s ease-in-out;
          //background: $selectionColor;
          border-left: rgb(236, 133, 19); solid 3px;
        }
      }

      a.paper-title {
        color: rgba(0,0,0,.84);
        text-decoration: none;
        display: block;
      }

      div.indent {
        margin: -0.5em 0 1.5em 0.5em;
      }

      div.tag-container {
        display: inline-block;
        margin: 0 0 0 5px;
        min-width: 65px;
        vertical-align: middle;
      }

      span.tag {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 0;
        margin-right: 5px;
      }
      
      .download-text {
        font-family: "SF Pro Text Regular", sans-serif;
        font-size: 16px;
        display: inline-block;
        margin-right: 1em;
      }
    }
  }
`;

const Papers = ({ paperItems, lang }) => {
  const [isBrowserReady, setBrowserReady] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(0);

  const updateState = () => {
    setBrowserReady(typeof window !== 'undefined');
    setViewportWidth(typeof window !== 'undefined' ? window.innerWidth : 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    updateState();
    window.addEventListener('resize', updateState);

    return () => {
      window.removeEventListener('resize', updateState);
    };
  }, []);

  const generatePaperInfo = (isEn, title, titleCN, tags, pathname, link, idx) => {
    return (
      <li key={idx}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{isEn ? title : title + '<br>' + titleCN}</ReactMarkdown>
        <div className="indent">
          {pathname && (
            <a href={hostPath + pathname} className={'download-text'} target="_blank" rel="noreferrer">
              {lang === 'en' ? 'pdf' : '下载'}
            </a>
          )}
          {link && (
            <a href={link} className={'download-text'} target="_blank" rel="noreferrer">
              {lang === 'en' ? 'link' : '链接'}
            </a>
          )}
          <div className="tag-container">{getTags(tags)}</div>
        </div>
      </li>
    );
  };

  const getTags = (tags, classname) =>
    tags.length > 1 ? (
      tags.map((num, idx) => <span className="tag" style={{ background: tagColors[num] }} key={classname || idx} />)
    ) : (
      <span className="tag" style={{ background: tagColors[tags] }} />
    );

  const getTagLabel = (category, idx) => (
    <div className="tag-label-container" key={category}>
      <div id={'color' + idx} className="label" key={'color' + idx} style={{ backgroundColor: tagColors[idx] }}>
        {category}
      </div>
    </div>
  );

  const { theme } = useContext(ThemeContext);
  const isEn = lang === 'en';
  const { seoTitle, seoTitleCN, categories, categoriesCN, shortCategories, shortCategoriesCN, items } = paperItems;
  const widthLimiter = 780;
  const tagLabels =
    viewportWidth < 700
      ? shortCategories.map((category, idx) => getTagLabel(category, idx))
      : categories.map((category, idx) => getTagLabel(category, idx));
  const tagLabelsCN =
    viewportWidth < 700
      ? shortCategoriesCN.map((category, idx) => getTagLabel(category, idx))
      : categoriesCN.map((category, idx) => getTagLabel(category, idx));
  const papers = items.map((item, idx) =>
    generatePaperInfo(isEn, item.title, item.titleCN, item.tags, item.pathname, item.link, idx)
  );
  const titles = items.map((item) => (isEn ? item.title : item.titleCN));

  return (
    <MainContentContainer theme={theme}>
      <PapersContainer theme={theme}>
        <Seo
          title={isEn ? seoTitle : seoTitleCN}
          description={isEn ? titles.join('. ') : titles.join('；')}
          location={isEn ? '/papers' : '/cn/papers'}
          lang={lang}
        />
        {isBrowserReady && viewportWidth >= widthLimiter && (
          <div className="options">
            <h2>{isEn ? 'Categories' : '类别'}</h2>
            <div className="sticky">{isEn ? tagLabels : tagLabelsCN}</div>
          </div>
        )}
        <div className="papers">
          <h2>{isEn ? 'A Few Papers' : '部分文章'}</h2>
          {isBrowserReady && viewportWidth < widthLimiter && (
            <div className="options">
              <div className="sticky">{isEn ? tagLabels : tagLabelsCN}</div>
            </div>
          )}
          <ul>{papers}</ul>
        </div>
      </PapersContainer>
    </MainContentContainer>
  );
};

export default Papers;
